import React from "react";


export default class SectionHeading extends React.Component {
  


  render() {

    return (
      <div className='cg-sectionHeading'>
        <div style={{margin: '2rem'}}>
          <h2 className='display-4' style={{margin: '0 auto', textAlign:'center', color:(this.props.darkbg)?'#ddd':'#172c50'}}>
            {this.props.children}
          </h2>
          {this.props.subtitle && 
            <div className='display-5' style={{margin: '0 auto', textAlign:'center', color:(this.props.darkbg)?'#ccc':'#172c50'}}>
              {this.props.subtitle}
            </div>
          }
          <hr style={{maxWidth: '8rem', margin: '1rem auto'}}/>
        </div>
    </div>
    );
  }
}