import React from "react";
//import { useStaticQuery, graphql, Link } from "gatsby";
import { Link } from "gatsby";
import logo from "../images/logo3_wide_bg_sm.webp";
//import Img from "gatsby-image";


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Logo = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     placeholderImage: file(relativePath: { eq: "logo2_fg.png" }) {
  //       childImageSharp {
  //         fixed(width: 60) {
  //           ...GatsbyImageSharpFixed
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <Link
        to="/"
        style={{
          color: `black`,
          textDecoration: `none`
        }}
    >
      {/*
      <Img id="headerLogo" fixed={data.placeholderImage.childImageSharp.fixed} />
      */}
      <img className="headerLogo" src={logo} alt=""/>
    </Link>
  )
}

export default Logo
