/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Footer from "./footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-image-lightbox/style.css';
import "./layout.css";
import Hr from "./hr";
//import DriftLoader from "./scriptLoader";

const Layout = ({ children }) => {

  return (
    <React.Fragment>

      {children}
      <Hr/>
      <Footer />
      {/*<DriftLoader />*/}
      
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
