import React from "react"
import Logo from "./logo"
import { Navbar, Nav } from "react-bootstrap"
import { Link } from "gatsby";

const Header = ({ ctaText, ctaLink, lmText, lmLink, active }) => (
  <header
      style={{
        margin: 0,
        paddingBottom: 0,
        backgroundImage: 'linear-gradient(rgba(0,0,0,255), rgba(0,0,0,0))'
      }} 
    >
      <hr style={{ margin:0 }}/>
      <a href="#main" id='skipnav' tabIndex='-1'>Skip to main content</a>
      
      
     
      <Navbar collapseOnSelect  expand='sm' bg="dark" variant="dark"> 
        {/* For the background color: see the override in layout.css */}
        <Navbar.Brand><Logo/></Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"/>
          <Nav>
            {(active!=='schedule') && 
            <React.Fragment>
              <a href="/photos" className='nav-link'>Photos</a>
              <Link to="/features" className={(active==='features')?'nav-link active':'nav-link'}>Features</Link>
              <a href="/pricing" className={(active==='pricing')?'nav-link active':'nav-link'}>Pricing</a>
            </React.Fragment>
            }
            
            {ctaLink &&
            <Link className="cg-dark btn btn-primary" to={ctaLink} >{ctaText}</Link>
            }
          </Nav>
        </Navbar.Collapse>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      </Navbar> 
      
  </header>
)

export default Header
