import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import Name from "./name"

export default () => {
    const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            tagline
          }
        }
      }
    `
  )
  
  return (
  <React.Fragment>
    <hr/>
    <div className="cg-hr">
        <Name/><span>: {site.siteMetadata.tagline}</span>
    </div>
    <hr/>
  </React.Fragment>
)}